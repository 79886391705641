import React from "react";
import "./style.css";
import Title from "../../components/Title/Title";
import Typo from "./../../utils/Typo/Typo";
import FromGroup from "./../../components/FromGroup/FromGroup";
import CheckBox from "./../../components/checkbox/CheckBox";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import CreditCard from "../../components/CheckoutPage/CreditCard/CreditCard";
import image from "../../assets/images/1.png";
const Checkout = () => {
  const porducts = [
    {
      img: image,
      name: "Product Name",
      price: "12.00",
    },
  ];

  return (
    <div className='checkout_page'>
      <Typo my={"10px"} position={"center"} variant={"h6"} fw={"bold"}>
        Registro Único de Mascotas del Perú
      </Typo>

      <div className='checkout_container'>
        <div className='container'>
          <div className='row gy-5'>
            <div className=' order-1 order-0 col-md-6 col-12  d-flex flex-column gap-3  '>
              <FromGroup rowCount={1}>
                <FromGroup.Input
                  label={
                    <Typo fw={"bolder"} variant={"h3"}>
                      Contacto
                    </Typo>
                  }
                  placeholder='Número de teléfono para contacto'
                />
              </FromGroup>
              <div className='d-flex align-items-center gap-3'>
                <input
                  type='checkbox'
                  style={{scale: "1.5", border: "none", outline: "none"}}
                />
                <div>
                  <Typo color={"dark1"} variant={"sm"}>
                    ¿Agregar envío?
                  </Typo>
                  <Typo color={"dark1"} variant={"sm"}>
                    Nuestro local de recojo gratuito se encuentra en la Av. Perú
                    cuadra 42, SMP (Lima)
                  </Typo>
                </div>
              </div>
              <FromGroup rowCount={1}>
                <CustomSelect
                  placeholder='Número de teléfono para contacto'
                  label={
                    <Typo fw={"bolder"} variant={"h3"}>
                      Delivery o envío
                    </Typo>
                  }
                />
              </FromGroup>
              <FromGroup rowCount={2}>
                <FromGroup.Input placeholder='Nombre completo' />
                <FromGroup.Input placeholder='DNI o C.E.' />
              </FromGroup>
              <FromGroup rowCount={1}>
                <FromGroup.Input
                  placeholder={
                    "Dirección (Delivery disponible en Lima Metropolitana)"
                  }
                />
                <FromGroup.Input
                  placeholder={
                    "Referencia (Delivery disponible en Lima Metropolitana)"
                  }
                />
              </FromGroup>
              <div className='d-flex gap-2 mt-3'>
                <Typo variant={"sm"}>*</Typo>
                <Typo variant={"sm"}>
                  El precio del envío puede variar de acuerdo a la distancia,
                  accesibilidad de la zona o tarifa de la empresa de transporte.
                </Typo>
              </div>
              <div className='d-flex gap-2'>
                <Typo variant={"sm"}>*</Typo>
                <Typo variant={"sm"}>
                  envíos fuera de Lima Metropolitana se realizan a través de
                  Olva o Shalom. En caso de preferir o necesitar otra empresa de
                  transporte, el precio podría ser distinto.
                </Typo>
              </div>
              <Typo fw={"bolder"} variant={"h3"}>
                Método de pago
              </Typo>{" "}
              <CreditCard />
              <Typo
                cursor={"pointer"}
                position={"center"}
                py={"10px"}
                br={"10px"}
                variant={"md"}
                color={"light"}
                bg={"rgb(36 49 94)"}
              >
                Pay now
              </Typo>
              <Typo
                cursor={"pointer"}
                position={"center"}
                py={"10px"}
                br={"10px"}
                variant={"h4"}
                fw={"semibold"}
                color={"dark"}
                bg={"#fff"}
              >
                Pay now
              </Typo>
            </div>
            <div className='col-md-6 order-0 order-md-1  col-12 px-5'>
              <div className='checkout_products'>
                {porducts.map((prod) => {
                  return (
                    <div className=' checkout_prod d-flex align-items-center justify-content-between'>
                      <div className='prod_image_name'>
                        <div className='prod_image'>
                          <img src={prod.img} alt='' />
                          <div className='count_padge'>1</div>
                        </div>
                        <Typo variant={"md"} fw={"bolder"}>
                          {prod.name}
                        </Typo>
                      </div>
                      <div>
                        <Typo variant={"md"}>${prod.price}</Typo>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="mt-4"></div>
              <div className='d-flex align-items-center justify-content-between'>
                <Typo fw={"bolder"} variant={"md"}>Subtotal (3 productos)</Typo>
                <Typo fw={"bolder"}>S/ 10</Typo>
              </div>
              <div className='d-flex align-items-center justify-content-between'>
                <Typo fw={"bolder"}  variant={"md"}s>Delivery o envío</Typo>
                <Typo  fw={"bolder"}>S/ 10</Typo>
              </div>
              <Typo position={"end"} mt={"10px"} fw={"bolder"} variant={"h2"}>
                S/ 60.00
              </Typo>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
