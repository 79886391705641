


import { createSlice } from '@reduxjs/toolkit';



const initialState = {
  cart: JSON.parse(localStorage.getItem("kspCart")) || []
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
    reducers: {
    addToCart: (state , action) => {
      // state.value += 1;
      const cartData = JSON.parse(localStorage.getItem("kspCart")) || [];
      const prodData  = action.payload


      if (cartData) {
        const prodExist = cartData.find((item) => item.product_code == prodData.product_code);
        if (prodExist) {
          const newData = cartData.map((item) => {
            if (item.product_code == prodData.product_code) {
              return {
                ...item,
                count: item.count + 1,
              };
            }
            return item;
          });
  
          localStorage.setItem("kspCart", JSON.stringify(newData));
          state.cart = newData
          return;
        }

        const dataset = {
          ...prodData,
          count: 1,
        };

        cartData.push(dataset);
        localStorage.setItem("kspCart", JSON.stringify(cartData));
        state.cart = cartData
      } else {
        const dataset = {
          ...prodData,
          count: 1,
        };
        localStorage.setItem("kspCart", dataset);
        state.cart = dataset

      }

    },
    
    minusFromCart: (state , action) => {
      const cartData = JSON.parse(localStorage.getItem("kspCart")) || [];
      const prodData  = action.payload


      if (cartData) {
        const newData = cartData.map((item) => {
          if (item.product_code == prodData.product_code) {
            return {
              ...item,
              count: +item.count - 1,
            };
          }
          return item;
        });
        localStorage.setItem("kspCart", JSON.stringify(newData));
        state.cart = newData
      }
    },
    // removeFromCart: (state, action) => {
    //   state.value += action.payload;
    // },
    clearCart:(state , action) =>{
      localStorage.removeItem("kspCart");
      state.cart = [];
    }
  },
});




export const { addToCart , minusFromCart , clearCart} = cartSlice.actions;

export default cartSlice.reducer;