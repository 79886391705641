import './style.css';
import productDetailsImg from '../../assets/images/product_details.png';
import { ovalIcon2 } from '../../assets/svgIcons';
import { useEffect, useState } from 'react';
import img1 from '../../assets/images/Screenshot_2024-08-29_165705-removebg-preview.png';
import img2 from '../../assets/images/Screenshot_2024-08-29_165627-removebg-preview.png';
import { AccordionItem } from 'react-bootstrap';
import ProductDetailAccordian from '../../components/ProductDetailAccordian/ProductDetailAccordian';
import { useLocation } from 'react-router-dom';

export default function ProductDetails() {
    const [number , setNumber] = useState(1);

    function handleIncrease() {
        setNumber(prevState => prevState+1);
    }

    function handleDecrease() {
        if(number < 1) {
            return;
        }
        setNumber(prevState => prevState-1);
    }

    const location = useLocation()
    const {data} = location?.state
    useEffect(()=>{
        console.log(data)
    },[data])
    

    return (
        <div className='product_details_page'>
            <div className='product_details_container'>
                <div className='product_details_img'>
                    <img src={data?.image} />
                </div>

                <div className='product_details_text_container'>
                    <h2 className='product_details_title'>
                        <img src={img1}/>
                        <span>Kit Básico RUMP</span>
                        <img src={img2}/>
                    </h2>

                    <ul>
                        <div>
                            <li>DNI ultra resistente</li>
                            <li>Plaquita grabada a laser</li>
                            <li>Collar decorativo (hasta 45 cm)</li>
                        </div>
                        <div>
                            <li>Certificado de Dueño Responsable</li>
                            <li>Certificado de Registro</li>
                        </div>
                    </ul>

                    <p>¡Evita que tu mascota se pierda! Con esta oferta tendrás una cobertura inicial que le
                        permitirá a tu mascotita ser identificada en caso de pérdida y permitirá que te
                        contacten para coordinar el regreso de tu engreido a tu hogar</p>

                    <ProductDetailAccordian />
                    
                    <div className='product_details_text_Incluye'>
                        <p>S/ 35</p>
                        <p>Incluye boleta o factura</p>
                    </div>

                    <div className='product_details_Comprar'>
                        <div className='product_details_number'>
                            <span onClick={handleDecrease}>-</span>
                            <div>
                                {number}
                            </div>
                            <span onClick={handleIncrease}>+</span>
                        </div>
                        <div
                            className='oval_button os_oval'
                            style={{
                                position: "relative",
                                fill: "rgb(248, 100, 8)",
                            }}
                        >
                            {ovalIcon2}

                            <div className='oval_btn_text'>Comprar
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}
