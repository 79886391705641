import React from "react";
import "./style.css";
import image from "../../assets/images/1.png";
import {exitModal} from "../../assets/svgIcons";
import { useNavigate } from "react-router-dom";
const CartPage = () => {
  const navigate = useNavigate()
  return (
    <div className='cart_page'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 col-12 overflow-auto '>
            <table className='product-table'>
              <thead>
                <tr>
                  <th>Producto</th>
                  <th>Precio</th>
                  <th>Cantidad</th>
                  <th>Total</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {[
                  "/path-to-img1.png",
                  "/path-to-img2.png",
                  "/path-to-img3.png",
                ].map((img, index) => (
                  <tr key={index}>
                    <td className='d-flex align-items-center'>
                      <img
                        src={image}
                        width={"150"}
                        alt='product'
                        className='product-image'
                      />
                      <span className='product-name'>Nombre del producto</span>
                    </td>
                    <td className='fw-bolder'>S/ 45</td>
                    <td>
                      <div className='quantity-control'>
                        <button>-</button>
                        <span>{1}</span>
                        <button>+</button>
                      </div>
                    </td>
                    <td className='fw-bolder'>S/ 45</td>
                    <td>
                      <button className='remove-button'>{exitModal}</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='col-lg-4 col-12 '>
            <div className='order-summary'>
              <h2>Tu orden</h2>
              <hr />
              <div className='order-total'>
                <span>Total</span>
                <span>S/ {70}</span>
              </div>
              <hr />
              <p>
                Costo del envío a provincia o delivery será calculado al
                realizar el pago.
              </p>
              <button className='pay-now'onClick={()=>navigate("/checkout")}>Pagar ahora</button>
              <button className='continue-shopping'>Seguir comprando</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartPage;
