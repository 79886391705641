import React, {useState} from "react";
import "./style.css";
import {
  cancel_btn,
  DeleteIcon,
  trashIcon,
  TrashIcon2,
} from "../../assets/svgIcons";
import cx from "classnames";
import img from "../../assets/images/1.png";
import Stepper from "./../Stepper/Stepper";
import Typo from "./../../utils/Typo/Typo";
import { useNavigate } from "react-router-dom";
const Cart = ({openCart, setOpenCart}) => {
  const [count, setCount] = useState(0);
  const navigate = useNavigate()
  return (
    <div
      className={cx("cart_container", {open: openCart})}
      onClick={(e) => {
        e.stopPropagation();
        setOpenCart(false);
      }}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className='cart_contnet shadow-lg'
      >
        <div className='cart_header'>
          <h2>Tu Carrito</h2>
          <div
            className='close_btn_cart'
            onClick={(e) => {
              e.stopPropagation();
              setOpenCart(false);
            }}
          >
            {cancel_btn}
          </div>
        </div>

        <div className='cart_prods'>
          <div className='cart_prod border-bottom border-light'>
            <div className='prod_image'>
              <img src={img} alt='' />
            </div>
            <div className='prod_data'>
              <h5 className='text-light mb-5'>Product Name</h5>
              <div className='d-flex align-items-center  gap-10 jusitfy-content-betweeen'>
                <Stepper value={count} onChange={setCount} />
                <div className='bg-light py-2 cursor-pointer'>{TrashIcon2}</div>
              </div>
            </div>
          </div>
          <div className='cart_prod border-bottom border-light'>
            <div className='prod_image'>
              <img src={img} alt='' />
            </div>
            <div className='prod_data'>
              <h5 className='text-light mb-5'>Product Name</h5>
              <div className='d-flex align-items-center  gap-10 jusitfy-content-betweeen'>
                <Stepper value={count} onChange={setCount} />
                <div className='bg-light py-2 cursor-pointer'>{TrashIcon2}</div>
              </div>
            </div>
          </div>
          <div className='d-flex aling-items-center justify-between'>
            <Typo variant={"lg"} color={"white"} fw={"bolder"}>
              Total
            </Typo>
            <Typo variant={"lg"} color={"white"}>
              S/ 70
            </Typo>
          </div>
          <Typo variant={"md"} color={"white"}>
            Costo del envío a provincia o delivery será calculado al realizar el
            pago.
          </Typo>

          <div className='cart-btns'>
            <Typo
            cursor={"pointer"}
              bg={"white"}
              br={"20px"}
              py={"5px"}
              position={"center"}
              color={"orange"}
              fw={"bolder"}
              variant={"h2"}
              onClick={()=> {
                navigate("/checkout");
                setOpenCart(false)
              }}

            >
              Pagar ahora
            </Typo>
            <Typo
            onClick={()=> {
              setOpenCart(false)
              navigate("/cart")
            }}
              mt={"20px"}
              bg={"white"}
              br={"20px"}
              cursor={"pointer"}
              py={"5px"}
              position={"center"}
              color={"orange"}
              fw={"bolder"}
              variant={"h2"}
            >
              Ver carrito
            </Typo>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
